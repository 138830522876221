import { FC } from "react";

import { Box, Text, createStyles } from "@mantine/core";

import TrackRouteLink from "@Components/TrackRouteLink/TrackRouteLink";
import { useFallbackImageUrl } from "@Lib/hooks/common/useFallbackImageUrl";
import { generateLinkUrl } from "@Lib/utils/routes";

const LOGO_CELL_WIDTH = 24;

const useStyles = createStyles(theme => ({
  cell: {
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing.xs,
    paddingBlock: 2,
  },
  link: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.other.extraSpacing.xxs,
    textDecoration: "none",
  },
  logo: {
    border: theme.other.getDefaultBorder(theme),
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: LOGO_CELL_WIDTH,
    height: LOGO_CELL_WIDTH,
    flex: `0 0 ${LOGO_CELL_WIDTH}px`,
    "& > img": {
      objectFit: "contain",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  name: {
    color: theme.colors.grey[9],
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fontWeight.semiBold,
  },
}));

type RaceToScaleCompanyCellProps = {
  id: string;
  name: string;
  logo: string;
};

const RaceToScaleCompanyCell: FC<RaceToScaleCompanyCellProps> = ({ id, name, logo }) => {
  const { classes } = useStyles();
  const [validUrl, handleFallback] = useFallbackImageUrl(logo);

  return (
    <Box className={classes.cell}>
      <TrackRouteLink className={classes.link} to={generateLinkUrl("organizationById", id)} title={name}>
        <Box className={classes.logo}>
          <img src={validUrl} alt={name} width={"auto"} height={"auto"} onError={handleFallback} />
        </Box>
        <Text className={classes.name} lineClamp={1}>
          {name}
        </Text>
      </TrackRouteLink>
    </Box>
  );
};

export default RaceToScaleCompanyCell;
