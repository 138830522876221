import { useQuery } from "@tanstack/react-query";

import { DEPLOYMENT_DEFAULT_FILTERS, QUERIES } from "@Lib/constants";
import { getDeploymentMapCoordinates } from "@Lib/services/sectorCompass";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";
import {
  type DeploymentMapResponse,
  type DeploymentMapCoordinatesBE,
  type DeploymentMapCoordinatesUI,
  type MarkerUI,
} from "@Lib/types/sectorCompass";
import { parseEntityBEId } from "@Lib/utils/queries";

const deploymentMapDataSelector = ({
  results,
  legend,
  root_sector_id,
}: DeploymentMapResponse): DeploymentMapCoordinatesUI => {
  const getProject = (location: DeploymentMapCoordinatesBE): MarkerUI["projects"][0] => ({
    id: location.id.toString(),
    name: location.project_name,
  });

  const markersMap = results.reduce<Record<string, MarkerUI>>((accum, curr) => {
    const markerId = `${curr.latitude},${curr.longitude}`;

    if (accum[markerId]) {
      accum[markerId].projects.push(getProject(curr));
      return accum;
    }

    accum[markerId] = {
      id: markerId,
      lat: Number(curr.latitude),
      lng: Number(curr.longitude),
      projects: [getProject(curr)],
      legendId: curr.legend_id.toString(),
    };

    return accum;
  }, {});

  return {
    markers: Object.values(markersMap),
    legend: legend.map(parseEntityBEId),
    rootSectorId: root_sector_id,
  };
};

export const useGetDeploymentMapCoordinates = (compassId: number) => {
  const filters = useDeploymentFilterStore(state => state.filters);

  return useQuery({
    queryKey: QUERIES.getDeploymentMapCoordinates(compassId, filters),
    queryFn: () => getDeploymentMapCoordinates(compassId, { ...DEPLOYMENT_DEFAULT_FILTERS, ...filters }),
    select: deploymentMapDataSelector,
  });
};
