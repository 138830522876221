import { ComponentType, FC } from "react";

import { Tooltip, Box } from "@mantine/core";
import { Trans } from "react-i18next";

import { useAuthContext } from "@Context/Auth";
import { Features, tierConfig } from "@Lib/config/userTier";

export const withUserEntitlementAccess =
  <P extends object>(Component: ComponentType<P>, feature: Features): FC<P> =>
  props => {
    const { user } = useAuthContext();
    const userTier = user?.accessLevel as keyof typeof tierConfig | undefined;

    const settings = userTier ? tierConfig[userTier]?.[feature] : undefined;

    if (!settings) {
      return <Component {...(props as P)} />;
    }

    const isDisabled = settings.isDisabled;

    const WrappedComponent = <Component {...(props as P)} disabled={isDisabled} />;

    if (settings.tooltipContent) {
      return (
        <Tooltip
          // @ts-expect-error --- using TranslationKeys in this case produces a union type that is too complex to represent and results in a warning. The fix is using more narrower type for these translations specifically which in this case seems unnecessary.
          label={<Trans i18nKey={settings.tooltipContent} components={{ b: <b /> }} />}
          position="bottom"
          maw={200}
          multiline
        >
          <Box>{WrappedComponent}</Box>
        </Tooltip>
      );
    }

    return WrappedComponent;
  };
