import { MantineTheme } from "@mantine/core";

import { type ThemeGetter } from "@Lib/types/base";

const getCommonCellPadding = (theme: MantineTheme) => ({
  padding: 7,
  "&:first-of-type": {
    paddingLeft: theme.spacing.sm,
  },
  "&:last-of-type": {
    paddingRight: theme.spacing.sm,
  },
});

export const tableStyles: ThemeGetter = theme => ({
  root: {
    borderSpacing: 0,
    height: "100%",
    width: "100%",
    color: theme.colors.grey[9],
    backgroundColor: theme.white,

    "& thead tr": {
      "& th": {
        top: 0,
        zIndex: 1,
        position: "sticky",
        color: theme.colors.grey[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: theme.other.fontWeight.semiBold,
        backgroundColor: theme.white,
        whiteSpace: "nowrap",
        border: "none",
        ...getCommonCellPadding(theme),

        "&.left": {
          textAlign: "left",
        },
        "&.center": {
          textAlign: "center",
        },
        "&.right": {
          textAlign: "right",
        },

        "&:after": {
          content: "''",
          position: "absolute",
          left: 0,
          width: "100%",
          bottom: 0,
          borderBottom: theme.other.getDefaultBorder(theme),
        },
      },
    },

    "& tbody tr": {
      ...theme.other.getRowHover(theme),

      "&:has(.no-hover):hover": {
        backgroundColor: "transparent",
      },

      "& td": {
        fontSize: theme.fontSizes.sm,
        borderBottom: theme.other.getDefaultBorder(theme),
        ...getCommonCellPadding(theme),

        "&.left": {
          textAlign: "left",
        },
        "&.center": {
          textAlign: "center",
        },
        "&.right": {
          textAlign: "right",
        },
      },
      "&:last-child td": {
        borderBottom: "none",
      },
    },
  },
});

export const paginationStyles: ThemeGetter = theme => ({
  item: {
    fontWeight: theme.other.fontWeight.regular,
    fontSize: theme.fontSizes.sm,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.grey[2]}`,
    borderRadius: theme.radius.xs,
    "&[data-active]": {
      color: theme.colors.white,
    },
  },
});
