import { useAuthContext } from "@Context/Auth";
import { AccessLevelEnum } from "@Lib/types/user";

type AccessLevelSwitchProps = {
  defaultComponent: React.ReactNode;
} & {
  [key in AccessLevelEnum]?: React.ReactNode;
};

const AccessLevelSwitch: React.FC<AccessLevelSwitchProps> = props => {
  const { user } = useAuthContext();
  const accessLevel = user?.accessLevel as AccessLevelEnum;

  return <>{props[accessLevel] || props.defaultComponent}</>;
};

export default AccessLevelSwitch;
