import { TranslationKeys } from "@Lib/i18n/config";
import { AccessLevels } from "@Lib/types/user";

export type Features = "downloads" | "analyst_call";

type FeatureSettings = {
  tooltipContent: TranslationKeys | null;
  isDisabled: boolean;
};

type SelectedAccessLevels = Pick<Record<AccessLevels, unknown>, "TRIAL" | "MONITOR">;

type TierConfig = {
  [tier in keyof SelectedAccessLevels]: {
    [feature in Features]?: FeatureSettings;
  };
};

export const tierConfig: TierConfig = {
  TRIAL: {
    downloads: {
      tooltipContent: "download.tierTooltips.trial",
      isDisabled: true,
    },
  },
  MONITOR: {
    analyst_call: {
      tooltipContent: "bookCallButtonText.tierTooltips.monitor",
      isDisabled: true,
    },
  },
};
