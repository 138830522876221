import { UserFE, type AccessLevels, type TestPhases } from "@Lib/types/user";

export type FeatureFlagNames =
  | "showProjectsDeploymentSectionForAllSectors"
  | "showProjectsDeploymentSection"
  | "showProjectsAsPreview"
  | "showProjects"
  | "hasUnlimitedDownloads"
  | "showTrialistBanner"
  | "showSavedSearch";

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

interface IncludedRoleFlag {
  includedRoles?: AccessLevels[];
}
interface ExcludedRoleFlag {
  excludedRoles?: AccessLevels[];
}
interface IncludedPhaseFlag {
  includedPhases?: TestPhases[];
}
interface ExcludedPhaseFlag {
  excludedPhases?: TestPhases[];
}
type FeatureFlag = { enabled: boolean } & XOR<IncludedRoleFlag, ExcludedRoleFlag> &
  XOR<IncludedPhaseFlag, ExcludedPhaseFlag>;

export const FEATURE_FLAGS: Record<FeatureFlagNames, FeatureFlag> = {
  showProjectsDeploymentSectionForAllSectors: { enabled: true, includedPhases: ["ALPHA"] },
  showProjectsDeploymentSection: { enabled: true, excludedRoles: ["TRIAL", "MONITOR"] },
  showProjectsAsPreview: { enabled: true, excludedRoles: ["LEGACY_PRO", "DEPLOY", "DEPLOY_PLUS"] },
  showProjects: { enabled: true },
  hasUnlimitedDownloads: { enabled: true, excludedRoles: ["TRIAL"] },
  showTrialistBanner: { enabled: true, includedRoles: ["TRIAL"] },
  showSavedSearch: { enabled: true, excludedRoles: ["TRIAL"] },
};

export const isFeatureAllowed = (featureFlagKey: FeatureFlagNames, user?: UserFE): boolean => {
  const feature = FEATURE_FLAGS[featureFlagKey];

  if (!feature.enabled) {
    return false;
  }

  let isAllowed = true;
  if (feature.excludedRoles && user?.accessLevel) {
    isAllowed = isAllowed && !feature.excludedRoles.includes(user.accessLevel);
  } else if (feature.includedRoles) {
    isAllowed = isAllowed && !!user?.accessLevel && feature.includedRoles.includes(user.accessLevel);
  }
  if (feature.excludedPhases && user?.testPhase) {
    isAllowed = isAllowed && !feature.excludedPhases.includes(user.testPhase);
  } else if (feature.includedPhases) {
    isAllowed = isAllowed && !!user?.testPhase && feature.includedPhases.includes(user.testPhase);
  }

  return isAllowed;
};
