import { FC } from "react";

import { Button, ButtonProps, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { withUserEntitlementAccess } from "@Components/user/withUserEntitlementAccess";

type DownloadButtonProps = ButtonProps & {
  onDownload: () => void;
  isDownloading: boolean;
};
const DownloadButton: FC<DownloadButtonProps> = ({ onDownload, isDownloading, ...buttonProps }) => {
  const { t } = useTranslation();

  return (
    <Button {...buttonProps} onClick={onDownload} size="xs" loading={isDownloading}>
      <Text size="sm">{t("download.btnText")}</Text>
    </Button>
  );
};

const TieredDownloadButton = withUserEntitlementAccess<DownloadButtonProps>(DownloadButton, "downloads");

export default TieredDownloadButton;
