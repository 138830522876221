import { ButtonStylesParams, CSSObject, MantineTheme } from "@mantine/core";

import { NAV_BAR_WIDTH_SMALL } from "@Lib/constants";
import { type ThemeGetter } from "@Lib/types/base";

export const appShellStyles: ThemeGetter = theme => ({
  root: {
    minWidth: theme.breakpoints.xs - NAV_BAR_WIDTH_SMALL,
    backgroundColor: theme.white,
    color: theme.colors.grey[9],
  },
  main: {
    height: "100vh",
    overflow: "auto",
  },
});

export const headerStyles: ThemeGetter = theme => ({
  root: {
    borderBottom: "none",
    backgroundColor: theme.colors.teal[5],
    display: "flex",
    alignItems: "center",
    gap: theme.spacing.sm,
  },
});

export const buttonStyles: ThemeGetter<ButtonStylesParams> = (theme, params) => ({
  root: {
    fontWeight: theme.other.fontWeight.semiBold,
    maxHeight: 32,
    ...renderButtonStylesForVariant(theme, params),
  },
  leftIcon: {
    marginRight: theme.spacing.xs,
  },
  rightIcon: {
    marginLeft: theme.spacing.xs,
  },
});

export const actionIconStyles: ThemeGetter<ButtonStylesParams> = () => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const menuStyles: ThemeGetter<{ color: string }> = (theme, { color = "grey" }) => ({
  dropdown: {
    border: theme.other.getDefaultBorder(theme),
    boxShadow: theme.shadows.sm,
  },
  item: {
    padding: "10px 16px",
    "&[data-hovered]": {
      backgroundColor: color === "indigo" ? theme.colors.indigo[1] : theme.colors[color][2],
    },
  },
});

export const modalStyles: ThemeGetter = theme => ({
  modal: {
    width: "fit-content",
  },
  header: {
    fontWeight: theme.other.fontWeight.semiBold,
    marginBottom: theme.spacing.xs,
  },
});

export const notificationStyles: ThemeGetter = theme => ({
  title: {
    color: theme.colors.teal[9],
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fontWeight.semiBold,
  },

  description: {
    color: theme.colors.grey[7],
  },

  loader: {
    marginLeft: theme.spacing.xs,
    marginRight: theme.spacing.sm,
    marginBlock: theme.spacing.xs,
  },

  icon: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 40,
    height: 40,
    width: 40,
    marginLeft: theme.spacing.xs,
    marginRight: theme.spacing.sm,
  },
});

export const imageStyles: ThemeGetter = theme => ({
  placeholder: {
    backgroundColor: theme.colors.grey[0],
  },
});

export const tabsStyles: ThemeGetter = theme => ({
  tabsList: {
    gap: theme.spacing.xs,
    borderBottom: theme.other.getDefaultBorder(theme),
  },

  tab: {
    marginBottom: -1,
    color: theme.colors.grey[5],
    paddingInline: 10,
    borderWidth: 2,
    fontWeight: theme.other.fontWeight.semiBold,
    fontSize: theme.fontSizes.md,
    "&[data-active]": {
      color: theme.colors.grey[9],
      borderColor: theme.colors.teal[5],
    },
  },
});

export const toopTipStyles: ThemeGetter = theme => ({
  tooltip: {
    backgroundColor: theme.colors.grey[2],
    color: theme.black,
    fontSize: theme.fontSizes.sm,
    paddingInline: theme.spacing.xs,
    borderRadius: theme.radius.xs,
  },
});

const renderButtonStylesForVariant = (theme: MantineTheme, params: ButtonStylesParams): CSSObject => {
  const { variant, color = "teal" } = params;
  const mainColor = theme.colors[color][5];
  const disabledState = {
    color: `${theme.colors.grey[8]} !important`,
    backgroundColor: theme.colors.grey[3],
    borderColor: theme.colors.grey[3],
  };
  const hoverColorFilled = color === "indigo" ? theme.colors.indigo[6] : theme.colors.teal[7];
  const hoverColorOutline = theme.colors[color][0];

  switch (variant) {
    case "filled":
      return {
        color: theme.white,
        backgroundColor: mainColor,

        "&:hover": {
          backgroundColor: hoverColorFilled,
          border: `1px solid ${hoverColorFilled}`,
        },

        "&:disabled": disabledState,
      };

    case "outline":
      return {
        color: mainColor,
        border: `1px solid ${mainColor}`,
        backgroundColor: theme.white,

        "&:hover": {
          backgroundColor: hoverColorOutline,
        },

        "&:disabled": disabledState,
      };

    case "subtle":
      return {
        color: mainColor,
        "&:hover": {
          backgroundColor: hoverColorOutline,
          // backgroundColor: "transparent",
          // color: theme.colors[color][6],
        },
        "&:disabled": disabledState,
      };

    default:
      return {};
  }
};
