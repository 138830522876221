import { useEffect, useState } from "react";

const DEFAULT_FALLBACK_IMAGE_URL = "/logo-placeholder.jpg";

const fetchImageAsBase64 = async (imageUrl: string): Promise<string> => {
  const response = await fetch(imageUrl, { mode: "cors" });
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`);
  }
  const blob = await response.blob();
  return await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const useFallbackImageUrl = (
  url: string,
  shouldPreload: boolean = false, // if image is used in combination with d3.js and html2canvas, it should be preloaded
  defaultUrl: string = DEFAULT_FALLBACK_IMAGE_URL
): [string, () => void] => {
  const [validUrl, setValidUrl] = useState(url);

  useEffect(() => {
    if (!shouldPreload) return;

    const preloadImage = async () => {
      try {
        setValidUrl(await fetchImageAsBase64(url));
      } catch {
        setValidUrl(await fetchImageAsBase64(defaultUrl));
      }
    };

    preloadImage();
  }, [url, shouldPreload, defaultUrl]);

  const handleFallback = () => {
    setValidUrl(defaultUrl);
  };

  return [validUrl, handleFallback];
};
