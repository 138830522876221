import { forwardRef } from "react";

import { Center, ScrollArea, createStyles, BoxProps, Box } from "@mantine/core";
import { UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import Card from "@Components/Cards/Card";
import SightlineChipRow from "@Components/Cards/Card/SightlineChipRow";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import { DATE_FORMAT } from "@Lib/constants";
import { SightlineCardUI } from "@Lib/types/sightlines";
import { getFormattedDate } from "@Lib/utils/date";
import { generateLinkUrl } from "@Lib/utils/routes";

const useStyles = createStyles(theme => ({
  content: {
    height: "100%",
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    marginInline: theme.spacing.sm,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: theme.spacing.sm,
    [theme.fn.smallerThan("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.fn.smallerThan("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

type SightlineSection = BoxProps & {
  query: UseQueryResult<SightlineCardUI[], unknown>;
};

const SightlineSection = forwardRef<HTMLDivElement, SightlineSection>(({ query }, ref) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      {query.isLoading ? (
        <Center className={classes.content}>{t("sightlinesPage.loading")}</Center>
      ) : query.isError ? (
        <ErrorBox text={t("sightlinesPage.errorText")} />
      ) : (
        <ScrollArea className={classes.content} ref={ref}>
          <Box className={classes.grid}>
            {query.data.map(({ id, title, rootSector, isPaid, coverImage, publishedAt, overview }) => (
              <Card
                key={id}
                title={title}
                subtitle={overview}
                imgSrc={coverImage}
                date={getFormattedDate(publishedAt, DATE_FORMAT)}
                linkText={t("sightlinesPage.cardLink")}
                goto={generateLinkUrl("sightlineById", id)}
                chips={<SightlineChipRow rootSector={rootSector} isPaid={isPaid} />}
                isSightline
              />
            ))}
          </Box>
        </ScrollArea>
      )}
    </>
  );
});

export default SightlineSection;
