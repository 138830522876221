import { FC } from "react";

import { Tooltip, Box, createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

import { useFallbackImageUrl } from "@Lib/hooks/common/useFallbackImageUrl";
import { type CompassCompany } from "@Lib/types/sectorCompass";
import { generateLinkUrl } from "@Lib/utils/routes";

const LOGO_WIDTH = 65;
const LOGO_HEIGHT = 45;
const useStyles = createStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: LOGO_WIDTH,
    height: LOGO_HEIGHT,
    flex: `0 0 ${LOGO_WIDTH}px`,
    borderRadius: theme.radius.xs,
    border: theme.other.getDefaultBorder(theme),
    backgroundColor: theme.white,
    "& > img": {
      objectFit: "contain",
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  hovered: {
    "&:hover": {
      borderColor: theme.other.compassSolutionColor,
      transform: "scale(1.05)",
      boxShadow: theme.shadows.sm,
    },
  },
}));

type CompanyItemProps = {
  company: CompassCompany;
};

const CompanyItem: FC<CompanyItemProps> = ({ company }) => {
  const { classes, cx } = useStyles();
  const { id, name, logo } = company;
  const [validUrl, handleFallback] = useFallbackImageUrl(logo);

  return (
    <Tooltip label={name}>
      <Box
        className={cx(classes.root, classes.hovered)}
        component={Link}
        to={generateLinkUrl("organizationById", id.toString())}
      >
        {<img src={validUrl} alt={name} width={"auto"} height={"auto"} onError={handleFallback} />}
      </Box>
    </Tooltip>
  );
};

export default CompanyItem;
