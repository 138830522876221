import { forwardRef } from "react";

import { BoxProps } from "@mantine/core";
import { useTranslation } from "react-i18next";

import { GoToLink, LinkRow } from "@Components/GoToLink";
import SightlineSection from "@Components/SightlineSection";
import { useGetSightlinesBySectorId } from "@Lib/hooks/sightlines";
import { generateLinkUrl } from "@Lib/utils/routes";

import SectionHolder from "../SectionHolder";

type SightlineListSection = BoxProps & {
  sectorId: number;
  sectorPath: string[];
};

const SightlineListSection = forwardRef<HTMLDivElement, SightlineListSection>(
  ({ sectorId, sectorPath, ...boxProps }, ref) => {
    const { t } = useTranslation();

    const query = useGetSightlinesBySectorId(sectorId);
    const gotoParams = new URLSearchParams({ sectorPath: sectorPath.join() });
    const goto = `${generateLinkUrl("sightlines")}?${gotoParams.toString()}`;

    return (
      <SectionHolder ref={ref} {...boxProps} title={t("sectorCompassPage.sections.sightlines.title")}>
        <SightlineSection query={query} />
        <LinkRow>
          <GoToLink to={goto} text={t("sectorCompassPage.sections.sightlines.link")} />
        </LinkRow>
      </SectionHolder>
    );
  }
);

export default SightlineListSection;
