import { useQuery } from "@tanstack/react-query";

import { projectStatusById } from "@Context/Theme/chartsColors";
import { DEPLOYMENT_DEFAULT_FILTERS } from "@Lib/constants";
import { QUERIES } from "@Lib/constants/queries";
import { getRaceToScaleProjects } from "@Lib/services/sectorCompass";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";
import {
  type RaceToScaleByProjectsResponse,
  type RaceToScaleByProjectsCompanyBE,
  type RaceToScaleCompanyUI,
  type RaceToScaleLegendConfig,
} from "@Lib/types/sectorCompass";
import { nullableToZero, nullableToString } from "@Lib/utils/queries";

type SelectorData = {
  dataset: RaceToScaleCompanyUI[];
  config: RaceToScaleLegendConfig[];
  totalResults: number;
};

const dataSelector = (data: RaceToScaleByProjectsResponse): SelectorData => {
  const { results, count } = data;

  const mapProject = ({
    id,
    name,
    scale_id,
    capacity_value_relative,
    progress,
    capacity_value,
    capacity_units,
  }: RaceToScaleByProjectsCompanyBE["projects"][0]): RaceToScaleCompanyUI["projects"][0] => ({
    id: id.toString(),
    name,
    scaleId: scale_id,
    capacityOffset: capacity_value_relative,
    statusColor: projectStatusById[progress.id],
    capacityValue: nullableToZero(capacity_value),
    capacityUnits: nullableToString(capacity_units),
  });

  const comapinesResult = new Map<number, RaceToScaleCompanyUI>();
  const config = new Map<string, RaceToScaleLegendConfig>();

  for (const company of results) {
    const { id, name, logo, projects: projectsBE } = company;

    const projects = [];
    for (const project of projectsBE) {
      if (!config.has(project.progress.name) && project.progress.id) {
        config.set(project.progress.name, {
          value: project.progress.name,
          color: projectStatusById[project.progress.id],
        });
      }
      projects.push(mapProject(project));
    }

    comapinesResult.set(id, {
      id: id.toString(),
      name,
      logo,
      projects,
    });
  }

  return {
    dataset: Array.from(comapinesResult.values()),
    config: Array.from(config.values()),
    totalResults: count,
  };
};

export const useGetRaceToScaleProjects = (sectorId: number, perPage: string) => {
  const filters = useDeploymentFilterStore(state => state.filters);

  return useQuery({
    queryKey: QUERIES.getCompassRaceToScaleProjects(sectorId, perPage, filters),
    queryFn: () =>
      getRaceToScaleProjects(sectorId, 1, parseInt(perPage), { ...DEPLOYMENT_DEFAULT_FILTERS, ...filters }),
    select: dataSelector,
  });
};
