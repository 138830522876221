import { FC, MouseEvent, ReactNode } from "react";

import { Box, BoxProps, Button, createStyles, Group, Text } from "@mantine/core";

import withAccessWrapper, { WithAccessWrapperProps } from "@Components/user/withAccessWrapper";

const useStyles = createStyles((theme, { variant }: { variant: BannerVariants }) => {
  const BANNER_COLORS = {
    neutral: {
      background: theme.colors.grey[2],
      button: theme.colors.teal[5],
    },
    primary: {
      background: theme.colors.teal[0],
      button: theme.colors.teal[5],
    },
    secondary: {
      background: theme.colors.indigo[0],
      button: theme.colors.indigo[5],
    },
  };

  return {
    root: {
      padding: 12,
      borderRadius: 4,
      backgroundColor: BANNER_COLORS[variant].background,
    },
    row: {
      display: "flex",
      gap: 12,
      flexWrap: "nowrap",
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rowTitle: {
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "flex-start",
    },
    heading: {
      fontSize: theme.fontSizes.sm,
      fontWeight: theme.other.fontWeight.semiBold,
    },
    text: {
      fontSize: theme.fontSizes.sm,
    },
    button: {
      color: BANNER_COLORS[variant].button,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  };
});

type BannerVariants = "neutral" | "primary" | "secondary";

type BannerProps = WithAccessWrapperProps &
  BoxProps & {
    variant?: BannerVariants;
    icon?: ReactNode;
    heading?: string;
    text?: string | ReactNode;
    buttonText?: string;
    clickHandler?: (e: MouseEvent<HTMLButtonElement>) => void;
  };

const Banner: FC<BannerProps> = ({
  variant = "neutral",
  icon,
  heading,
  text,
  buttonText,
  clickHandler,
  className,
  ...boxProps
}) => {
  const { classes, cx } = useStyles({ variant });

  const handleClick = clickHandler ?? undefined;

  return (
    <Box className={cx(classes.root, className)} {...boxProps}>
      <Group className={classes.row}>
        {icon && <Box className={classes.iconContainer}>{icon}</Box>}
        <Group spacing={0} className={classes.rowTitle}>
          {heading && <Text className={classes.heading}>{heading}</Text>}
          {text && <Text className={classes.text}>{text}</Text>}
        </Group>
        {clickHandler && (
          <Button className={classes.button} onClick={handleClick} variant="subtle" p={0}>
            {buttonText}
          </Button>
        )}
      </Group>
    </Box>
  );
};

export default withAccessWrapper(Banner);
