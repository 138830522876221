import { useQuery } from "@tanstack/react-query";

import { DEPLOYMENT_DEFAULT_FILTERS } from "@Lib/constants";
import { QUERIES } from "@Lib/constants/queries";
import { getRaceToScaleCompanies } from "@Lib/services/sectorCompass";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";
import {
  type RaceToScaleByCompaniesResponse,
  type RaceToScaleByCompaniesBE,
  type RaceToScaleTechnologyUI,
} from "@Lib/types/sectorCompass";

type SelectorData = {
  dataset: RaceToScaleTechnologyUI[];
  totalResults: number;
};

const dataSelector = (data: RaceToScaleByCompaniesResponse): SelectorData => {
  const mapCompany = ({
    id,
    name,
    logo,
    scale_id,
  }: Omit<RaceToScaleByCompaniesBE, "technology">): RaceToScaleTechnologyUI["companies"][0] => ({
    id: id.toString(),
    name,
    logo,
    scaleId: scale_id,
  });

  const technologiesResult = new Map<number, RaceToScaleTechnologyUI>();
  const duplicationGuard = new Set<number>();

  for (const { technology, ...company } of data) {
    if (duplicationGuard.has(company.id)) {
      continue;
    }
    duplicationGuard.add(company.id);

    for (const tech of technology) {
      const prevTech = technologiesResult.get(tech.id);

      if (!prevTech) {
        technologiesResult.set(tech.id, {
          id: tech.id.toString(),
          name: tech.name,
          companies: [mapCompany(company)],
        });
        continue;
      }

      technologiesResult.set(tech.id, {
        id: tech.id.toString(),
        name: tech.name,
        companies: [...prevTech.companies, mapCompany(company)],
      });
    }
  }

  return {
    dataset: Array.from(technologiesResult.values()),
    totalResults: data.length,
  };
};

export const useGetRaceToScaleCompanies = (sectorId: number, perPage: string) => {
  const filters = useDeploymentFilterStore(state => state.filters);

  return useQuery({
    queryKey: QUERIES.getCompassRaceToScaleCompanies(sectorId, perPage, filters),
    queryFn: () =>
      getRaceToScaleCompanies(sectorId, 1, parseInt(perPage), { ...DEPLOYMENT_DEFAULT_FILTERS, ...filters }),
    select: dataSelector,
  });
};
