import { FC } from "react";

import { Box, Tooltip, createStyles } from "@mantine/core";

import TrackRouteLink from "@Components/TrackRouteLink/TrackRouteLink";
import { useFallbackImageUrl } from "@Lib/hooks/common/useFallbackImageUrl";
import { type RaceToScaleTechnologyUI } from "@Lib/types/sectorCompass";
import { generateLinkUrl } from "@Lib/utils/routes";

const useStyles = createStyles((theme, isFirstRow: boolean) => ({
  cell: {
    padding: theme.spacing.xs,
    display: "flex",
    flexWrap: "wrap",
    gap: 3,
    borderRight: theme.other.getDefaultBorder(theme),
    borderTop: isFirstRow ? "none" : theme.other.getDefaultBorder(theme),
    "&:nth-of-type(5n)": {
      borderRight: "none",
    },
  },
  logo: {
    height: 30,
    width: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    border: theme.other.getDefaultBorder(theme),
    borderRadius: theme.radius.xs,
    "& > img": {
      objectFit: "contain",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: theme.shadows.sm,
    },
  },
}));

type RaceToScaleCellProps = {
  isFirstRow: boolean;
  companies: RaceToScaleTechnologyUI["companies"];
};

const RaceToScaleCell: FC<RaceToScaleCellProps> = ({ companies, isFirstRow }) => {
  const { classes } = useStyles(isFirstRow);

  return (
    <Box className={classes.cell}>
      {companies.map(({ id, name, logo }) => {
        return (
          <Tooltip key={id} label={name}>
            <TrackRouteLink className={classes.logo} to={generateLinkUrl("organizationById", id)}>
              <Image name={name} logo={logo} />
            </TrackRouteLink>
          </Tooltip>
        );
      })}
    </Box>
  );
};

interface ImageProps {
  name: string;
  logo: string;
}

const Image = ({ name, logo }: ImageProps) => {
  const [validUrl, handleFallback] = useFallbackImageUrl(logo);
  return <img src={validUrl} alt={name} width={"auto"} height={"auto"} onError={handleFallback} />;
};

export default RaceToScaleCell;
