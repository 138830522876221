import { FC } from "react";

import { NotificationsProvider } from "@mantine/notifications";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRoutes } from "react-router-dom";

import ErrorBoundary from "@Components/ErrorBoundary";
import { QueryProvider, ThemeProvider, AuthProvider, ModalsProvider, UserTrackingProvider } from "@Context/index";
import { NOTIFICATION_CLOSE_TIME } from "@Lib/constants";

import { routes } from "./Routes";

const App: FC = () => {
  const routesContent = useRoutes(routes);

  return (
    <QueryProvider>
      <ThemeProvider>
        <NotificationsProvider autoClose={NOTIFICATION_CLOSE_TIME}>
          <ErrorBoundary isRoot>
            <AuthProvider>
              <UserTrackingProvider>
                <ModalsProvider>{routesContent}</ModalsProvider>
              </UserTrackingProvider>
            </AuthProvider>
          </ErrorBoundary>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </NotificationsProvider>
      </ThemeProvider>
    </QueryProvider>
  );
};

export default App;
