import { FC } from "react";

import { Box, Image, ImageProps } from "@mantine/core";

type ImagePlaceholderProps = ImageProps & {
  imageSource?: string;
};

const ImagePlaceholder: FC<ImagePlaceholderProps> = ({ imageSource = "/logo-placeholder.jpg", ...props }) => {
  return (
    <Box>
      <Image src={imageSource} {...props} />
    </Box>
  );
};
export default ImagePlaceholder;
