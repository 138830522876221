import { forwardRef, useMemo } from "react";

import { ActionIcon, Image, Modal, BoxProps, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

import MaximizeIcon from "@Assets/maximize-02.svg?react";
import MinimizeIcon from "@Assets/minimize-02.svg?react";
import PageSection from "@Components/PageSection/PageSection";

import TieredDownloadButton from "./DownloadButton";

const useModalStyles = createStyles(theme => ({
  inner: {
    padding: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    overflow: "hidden",
    backgroundColor: "transparent",
    flex: 1,
  },
  body: {
    height: "calc(100vh - 8px)",
    "& *:not(.close-btn)": {
      height: "100%",
    },
    "& button.close-btn": {
      position: "fixed",
      top: 4,
      right: 4,
      color: theme.white,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      borderRadius: theme.radius.md,
    },
  },
}));

type SectionHolderProps = BoxProps & {
  title: string;
  onDownload?: () => void;
  isDownloading?: boolean;
  imgSrc?: string;
};

const SectionHolder = forwardRef<HTMLDivElement, SectionHolderProps>(
  ({ title, onDownload, isDownloading = false, imgSrc, children, ...boxProps }, ref) => {
    const { t } = useTranslation();
    const { classes: modalClasses } = useModalStyles();

    const [expanded, handlers] = useDisclosure(false);
    const hideDownload = !onDownload;

    const buttons = useMemo(() => {
      const buttons = hideDownload
        ? []
        : [<TieredDownloadButton key="download" onDownload={onDownload} isDownloading={isDownloading} />];

      if (imgSrc) {
        buttons.unshift(
          <ActionIcon key="fullscreen" onClick={handlers.open} title={t("sectorCompassPage.fullscreenBtn")}>
            <MaximizeIcon />
          </ActionIcon>
        );
      }

      return buttons;
    }, [isDownloading, hideDownload]);

    return (
      <PageSection ref={ref} title={title} {...boxProps} buttons={buttons}>
        {children}
        <Modal opened={expanded} onClose={handlers.close} withCloseButton={false} padding={0} classNames={modalClasses}>
          <Image src={imgSrc} fit="contain" height={"100%"} onClick={handlers.close} />
          <ActionIcon className="close-btn" onClick={handlers.close} title={t("sectorCompassPage.exitFullscreenBtn")}>
            <MinimizeIcon />
          </ActionIcon>
        </Modal>
      </PageSection>
    );
  }
);

export default SectionHolder;
