import { useCallback } from "react";

import { useQuery } from "@tanstack/react-query";

import { getTotalKey } from "@Components/SectorCompass/components/DeploymentSection/common/utils";
import { projectStatusById, sectorBarsById, sectorDarkerBars, sectorLighterBars } from "@Context/Theme/chartsColors";
import { DEPLOYMENT_DEFAULT_FILTERS, ProjectsGroupByEnum, QUERIES } from "@Lib/constants";
import { getOperationalChartData } from "@Lib/services/sectorCompass";
import { useDeploymentFilterStore } from "@Lib/store/sectorCompass/deployment";
import { ProjectsChartConfig, ProjectsChartRespponse, ProjectsOperationsChartDatum } from "@Lib/types/sectorCompass";
import { generateDarkToLightColorShades } from "@Lib/utils/colors";

type SelectorData = {
  units: string;
  dataset: ProjectsOperationsChartDatum[];
  config: ProjectsChartConfig[];
};

const dataSelector = (
  { units, results }: ProjectsChartRespponse,
  rootSectorId: number,
  groupBy: ProjectsGroupByEnum
): SelectorData => {
  const START_DATE = 2020;
  const fixDecimalSum = (sum: number) => {
    const roundingFactor = 100;

    return Math.round(sum * roundingFactor) / roundingFactor;
  };

  let hasStartDateGroup = false;
  const baseItem = results.reduce((accum, { group: { name }, year }) => {
    if (year === START_DATE) {
      hasStartDateGroup = true;
    }

    return {
      ...accum,
      [name]: 0,
    };
  }, {});

  const resultMap: Record<string, ProjectsOperationsChartDatum> = {};
  const config = new Map<string, ProjectsChartConfig>();

  const cacheTotal: Record<string, number> = {};

  for (const result of results) {
    const {
      group: { id, name: groupName },
      capacity_value,
      year,
    } = result;

    if (!resultMap[year]) {
      if (!hasStartDateGroup) {
        resultMap[START_DATE] = {
          group: START_DATE.toString(),
          ...baseItem,
        } as ProjectsOperationsChartDatum;
      }

      resultMap[year] = {
        group: year.toString(),
        ...baseItem,
      } as ProjectsOperationsChartDatum;
    }

    cacheTotal[groupName]
      ? fixDecimalSum((cacheTotal[groupName] += capacity_value))
      : (cacheTotal[groupName] = capacity_value);

    // Holds comulative data over the years
    resultMap[year][getTotalKey(groupName)] = cacheTotal[groupName];
    // Holds data for the current period
    resultMap[year][groupName] = capacity_value;

    if (!config.has(groupName) && id) {
      config.set(groupName, {
        dataKey: groupName,
        fill: projectStatusById[id],
      });
    }
  }

  if (groupBy !== ProjectsGroupByEnum.progress) {
    const baseColor = sectorBarsById[rootSectorId];
    const shades = generateDarkToLightColorShades({
      baseColor,
      endDarkColor: sectorDarkerBars[baseColor],
      endLightColor: sectorLighterBars[baseColor],
      count: config.size,
    });

    let idx = 0;
    config.forEach((value, key) => {
      config.set(key, { ...value, fill: shades[idx] });
      idx++;
    });
  }

  const fullDataset = Object.values(resultMap);
  const dataset = fullDataset.filter(item => parseInt(item.group) >= START_DATE);
  const prevDummyGroupIdx = fullDataset.length - dataset.length - 1;

  if (!hasStartDateGroup) {
    if (prevDummyGroupIdx > -1) {
      // We have cumulative data in perv period group, we must merge it in
      dataset[0] = Object.assign({}, fullDataset[prevDummyGroupIdx], dataset[0]);
    } else {
      for (const key of Object.keys(dataset[0])) {
        // We generate the total keys, since they are not part of the `baseItem`
        dataset[0][getTotalKey(key)] = dataset[0][key];
      }
    }
  }

  const sortedConfig =
    groupBy === ProjectsGroupByEnum.progress ? Array.from(config.values()).reverse() : Array.from(config.values());

  return {
    units: units ?? "",
    dataset,
    config: sortedConfig,
  };
};

export const useGetOperationalChartData = (sectorId: number, rootSectorId: number, groupBy: ProjectsGroupByEnum) => {
  const filters = useDeploymentFilterStore(state => state.filters);
  const select = useCallback(
    ({ results, ...data }: ProjectsChartRespponse) =>
      dataSelector(
        {
          ...data,
          results: results.sort((a, b) => a.year - b.year),
        },
        rootSectorId,
        groupBy
      ),
    [rootSectorId, groupBy]
  );

  return useQuery({
    queryKey: QUERIES.getCompassOperationalChart(sectorId, groupBy, filters),
    queryFn: () => getOperationalChartData(sectorId, groupBy, { ...DEPLOYMENT_DEFAULT_FILTERS, ...filters }),
    select,
  });
};
